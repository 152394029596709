<template>
  <AppHeader />
  <Suspense>
    <template #default>
      <Subscribes />
    </template>
    <template #fallback>
      <Spinner />
    </template>
  </Suspense>
  <AppFooter />
</template>

<script>
import { defineAsyncComponent, Suspense } from "vue";
import Spinner from "@/components/Spinners/Spinner.vue";
import AppHeader from "@/components/layouts/AppHeader.vue";
import AppFooter from "@/components/layouts/AppFooter.vue";
const Subscribes = defineAsyncComponent(() =>
  import("@/components/Users/Subscribes.vue")
);
export default {
  name: 'Subscribe',
  components: {
    Suspense,
    Spinner,
    AppHeader,
    AppFooter,
    Subscribes
  },
}
</script>